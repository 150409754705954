import React, {useEffect,useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import AuthService from "../../../Services/auth/authAPI";
import { ReactComponent as Loader } from '../../assets/loader.svg'

const Manager_header = () => {

    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());

    const handleLogout = () => {
        AuthService.logout();
        navigate("/login")
    };


    return (
        <header id="header" className="header fixed-top d-flex align-items-center bg-dark">

            <div className="d-flex align-items-center justify-content-between">
                <Link to="/admin" className="logo d-flex align-items-center">
                    <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="" />
                    <span className="d-none d-lg-block text-white">KKS Manager</span>
                </Link>
                <i className="text-white bi bi-list toggle-sidebar-btn"></i>
            </div>

            <div className="search-bar">
                {/*<form className="search-form d-flex align-items-center" method="POST" action="#">*/}
                    {/*<input type="text" name="query" placeholder="Search" title="Enter search keyword" />*/}
                    {/*<button type="submit" title="Search"><i className="bi bi-search"></i></button>*/}
                {/*</form>*/}
            </div>

            <div className="search-bar">
                {me && (<><h5 className="text-white">Ville <i className="bx bx-current-location"></i> :
                    <b className="text-white">{me.user.city}</b></h5></>)}
            </div>


            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">

                    <li className="nav-item d-block d-lg-none">
                        <a className="nav-link nav-icon search-bar-toggle " href="#">
                            <i className="bi bi-search"></i>
                        </a>
                    </li>


                    <li className="nav-item dropdown pe-3">

                        <a className="text-white nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            {/*<img src={process.env.PUBLIC_URL + '/assets/img/profile-img.jpg'} alt="Profile" className="rounded-circle" />*/}
                            <span className="d-none d-md-block dropdown-toggle ps-2">{me && (<>{me.user.name}</>)}</span>
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6>{me && (<>{me.user.name}</>)}</h6>
                                <span>Gestionnaire bus</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>

                            {/*<li>*/}
                                {/*<a className="dropdown-item d-flex align-items-center" href="users-profile.html">*/}
                                    {/*<i className="bi bi-person"></i>*/}
                                    {/*<span>My Profile</span>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<a className="dropdown-item d-flex align-items-center" href="users-profile.html">*/}
                                    {/*<i className="bi bi-gear"></i>*/}
                                    {/*<span>Account Settings</span>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<a className="dropdown-item d-flex align-items-center" href="pages-faq.html">*/}
                                    {/*<i className="bi bi-question-circle"></i>*/}
                                    {/*<span>Need Help?</span>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            <li>
                                <button className="dropdown-item d-flex align-items-center text-danger" onClick={handleLogout}>
                                    <i className="bi bi-box-arrow-right"></i>
                                    <span>Se deconnecter</span>
                                </button>
                            </li>

                        </ul>
                    </li>

                </ul>
            </nav>

        </header>
    );
};

export default Manager_header;