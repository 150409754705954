import axios from "axios";
import { FRET_BUSES_API, FRET_SETTING_API, FRET_POS_API} from "../../config";
import Cache from "../cache";

async function findSetting() {
    // const cachedCustomSetting = await Cache.get("CustomSetting");
    // if(cachedCustomSetting) return cachedCustomSetting;
    return axios
        .get(FRET_SETTING_API)
        .then(response => {
            const setting = response.data.setting;
            //Cache.set("CustomSetting", setting);
            return response.data.setting;
        });
}


async function fretCatalogues() {
    return axios
        .get(FRET_POS_API + "/fretCatalogues")
        .then(response => {
            const catalogues = response.data.catalogues;
            return response.data.catalogues;
        });
}


async function findPos() {
    // const cachedCustomPos = await Cache.get("CustomPos");
    // if(cachedCustomPos) return cachedCustomPos;
    return axios
        .get(FRET_POS_API + "/getPos")
        .then(response => {
            const pos = response.data.pos;
            //Cache.set("CustomPos", pos);
            return response.data.pos;
        });
}

async function findOneTicket(id){
    // const cachedCustomerTickets = await Cache.get("CustomerTickets." + id);
    // if(cachedCustomerTickets) return cachedCustomerTickets;
    return axios.get(FRET_POS_API + "/tickets/" + id)
        .then(response => {
            const invoice = response.data;
            //Cache.set("CustomerTickets." + id, invoice);
            return response.data;
        });
}


async function findOneFret(id){
    // const cachedCustomerTickets = await Cache.get("CustomerTickets." + id);
    // if(cachedCustomerTickets) return cachedCustomerTickets;
    return axios.get(FRET_POS_API + "/frets/" + id)
        .then(response => {
            const invoice = response.data;
            //Cache.set("CustomerTickets." + id, invoice);
            return response.data;
        });
}

async function getSheduledAssignmentsBuses() {
    return axios
        .get(FRET_BUSES_API + "/getSheduledAssignmentsBuses")
        .then(response => {
            const assignments = response.data.assignments;
            //Cache.set("CustomerAssignments", assignments);
            return response.data.assignments;
        });
}


async function getDirectAssignmentsBuses() {
    // const cachedCustomerAssignments = await Cache.get("CustomerAssignments");
    // if(cachedCustomerAssignments) return cachedCustomerAssignments;
    return axios
        .get(FRET_BUSES_API + "/getDirectAssignmentsBuses")
        .then(response => {
            const assignments = response.data.assignments;
            //Cache.set("CustomerAssignments", assignments);
            return response.data.assignments;
        });
}


async function getTransitAssignmentsBuses() {
    // const cachedCustomerAssignments = await Cache.get("CustomerAssignments");
    // if(cachedCustomerAssignments) return cachedCustomerAssignments;
    return axios
        .get(FRET_BUSES_API + "/getTransitAssignmentsBuses")
        .then(response => {
            const assignments = response.data.assignments;
            //Cache.set("CustomerAssignments", assignments);
            return response.data.assignments;
        });
}


async function getTrajets() {
    // const cachedCustomerTrajets = await Cache.get("CustomerTrajets");
    // if(cachedCustomerTrajets) return cachedCustomerTrajets;
    return axios
        .get(FRET_POS_API + "/getTrajets")
        .then(response => {
            const trajets = response.data.trajets;
            // Cache.set("CustomerTrajets", trajets);
            return response.data.trajets;
        });
}

async function dailyAccompagneFrets(){
    return axios
        .get(FRET_POS_API + "/dailyAccompagneFrets")
        .then(response => {
            const tickets = response.data.frets;
            return response.data;
        });
}

async function dailyUnAccompagneFrets(){
    return axios
        .get(FRET_POS_API + "/dailyUnAccompagneFrets")
        .then(response => {
            const tickets = response.data.frets;
            return response.data;
        });
}
async function dailyFrets() {
    return axios
        .get(FRET_POS_API + "/dailyFrets")
        .then(response => {
            const tickets = response.data.frets;
            return response.data;
        });
}

async function dailyFretsForAmbarquement() {
    return axios
        .get(FRET_POS_API + "/dailyFretsForAmbarquement")
        .then(response => {
            const tickets = response.data.frets;
            //console.log(response.data);
            return response.data;
        });
}


async function dailySales() {
    return axios
        .get(FRET_POS_API + "/dailySales")
        .then(response => {
            //const tickets = response.data.tickets;
            //console.log(response.data);
            return response.data;
        });
}

async function sheduledSales() {
    return axios
        .get(FRET_POS_API + "/dailySheduledSales")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}

async function dailyDirectSalesForEmbarquement() {
    return axios
        .get(FRET_POS_API + "/dailyDirectSalesForEmbarquement")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}

async function dailyDirectSales() {
    return axios
        .get(FRET_POS_API + "/dailyDirectSales")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function dailySpents() {
    return axios
        .get(FRET_POS_API + "/dailySpents")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}

async function dailyBusSpents() {
    return axios
        .get(FRET_POS_API + "/dailyBusSpents")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function allSpents() {
    return axios
        .get(FRET_POS_API + "/allSpents")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function allBusSpents() {
    return axios
        .get(FRET_POS_API + "/allBusSpents")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function getOneBusSpent(id) {
    return axios
        .get(FRET_POS_API + "/getOneBusSpent/"+id)
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data.spent;
        });
}



async function dailyTransitsSales() {
    return axios
        .get(FRET_POS_API + "/dailyTransitsSales")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function allSales() {
    return axios
        .get(FRET_POS_API + "/allSales")
        .then(response => {
            //const tickets = response.data.tickets;
            return response.data;
        });
}


async function allFrets() {
    return axios
        .get(FRET_POS_API + "/allFrets")
        .then(response => {
            const tickets = response.data.tickets;
            return response.data;
        });
}

async function allWeeklyFrets(){
    return axios
        .get(FRET_POS_API + "/allWeeklyFrets")
        .then(response => {
            const tickets = response.data.tickets;
            return response.data;
        });
}



function storeTickets(ticket){

    return axios.post(FRET_POS_API + "/storeTickets", ticket)
        .then(async response => {
            // const cachedCustomerTickets = await Cache.get("CustomerTickets");
            //
            // if(cachedCustomerTickets){
            //     Cache.set("CustomerTickets", [...cachedCustomerTickets, response.data.ticket]);
            // }

            return response;
        });
}

function storeFret(fret){

    return axios.post(FRET_POS_API + "/storeFrets", fret)
        .then(async response => {
            console.log(response);
            return response;
        });
}


function storeBusSpent(busSpent){

    return axios.post(FRET_POS_API + "/storeBusSpents", busSpent)
        .then(async response => {
            return response;
        });
}

function storeSpent(busSpent){

    return axios.post(FRET_POS_API + "/storeSpents", busSpent)
        .then(async response => {
            return response;
        });
}


function updateAssignment(id, assignment){

    return axios.post(FRET_BUSES_API + "/editBusAssignment", {...assignment, id: id})
        .then(async response => {
            const cachedManagerDailyAssignments = await Cache.get("ManagerDailyAssignments");

            const cachedManagerDailyAssignment = await Cache.get("ManagerDailyAssignments." + id);

            if(cachedManagerDailyAssignment){
                Cache.set("ManagerDailyAssignments." + id, response.data.assignment);
            }

            if(cachedManagerDailyAssignments){
                const index = cachedManagerDailyAssignments.findIndex(b => b.id === +id);
                cachedManagerDailyAssignments[index] = response.data.assignment;
            }

            return response;
        });
}

function addFretToBus(item, bus) {
    const fretItem = {itemId : item, busId: bus};
    return axios
        .post(FRET_BUSES_API + "/addFretToBus", fretItem)
        .then(async response => {
            return response;
        });
}


function closedBus(assig) {

    return axios
        .post(FRET_BUSES_API + "/closedBus", {id:assig})
        .then(async response => {
            return response;
        });
}





export default {
    closedBus,
    addFretToBus,
    findSetting,
    findPos,
    getDirectAssignmentsBuses,
    getSheduledAssignmentsBuses,
    getTransitAssignmentsBuses,
    getTrajets,
    dailySales,
    dailyDirectSales,
    dailyDirectSalesForEmbarquement,
    getOneBusSpent,
    dailySpents,
    dailyBusSpents,
    allSpents,
    allBusSpents,
    dailyTransitsSales,
    storeTickets,
    storeFret,
    storeBusSpent,
    storeSpent,
    allSales,
    findOneTicket,
    findOneFret,
    fretCatalogues,
    dailyFrets,
    dailyAccompagneFrets,
    dailyUnAccompagneFrets,
    dailyFretsForAmbarquement,
    allFrets,
    allWeeklyFrets,
    sheduledSales
};