import React, {useEffect,useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import AuthService from "../../../Services/auth/authAPI";
import {toast} from "react-toastify";
import CustomAPI from "../../../apis/customerApi/VendeurAPI";

const Customer_header = () => {

    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());
    const [loading, setLoading] = useState(true);

    const handleLogout = () => {
        AuthService.logout();
        navigate("/login")
    };

    const [setting, setSetting] = useState();
    const [pos, setPos] = useState();

    const fetchSetting = async () => {
        try{
            const data =  await CustomAPI.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };


    const fetchPos = async () => {
        try{
            const data =  await CustomAPI.findPos();
            setPos(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };


    useEffect(() => {
        fetchSetting();
        fetchPos()
    },[]);

    return (
        <header id="header" className="header fixed-top d-flex align-items-center">

            <div className="d-flex align-items-center justify-content-between">
                <Link to="/admin" className="logo d-flex align-items-center">
                    <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="" />
                    <span className="d-none d-lg-block">KKS EXPRESS BILLETTERIE</span>
                </Link>
                <i className="bi bi-list toggle-sidebar-btn" id="toggle-sidebar-btn"></i>
            </div>


            <div className="search-bar">
                {setting && <h5 className="text-dark">Taux : <b className="text-primary">{setting.currency} CDF</b></h5>}
            </div>
            <div className="search-bar">
                {pos && <h5 className="text-dark">PdV : <b className="text-primary">{pos.pos_name}</b></h5>}
            </div>
            <div className="search-bar">
                {pos && <h5 className="text-dark">Ville : <b className="text-primary">{pos.pos_city}</b></h5>}
            </div>

            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">

                    <li className="nav-item d-block d-lg-none">
                        <a className="nav-link nav-icon search-bar-toggle " href="#">
                            <i className="bi bi-search"></i>
                        </a>
                    </li>

                    {/*<li className="nav-item dropdown">*/}

                        {/*<a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">*/}
                            {/*<i className="bi bi-bell"></i>*/}
                            {/*<span className="badge bg-primary badge-number">4</span>*/}
                        {/*</a>*/}

                        {/*<ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">*/}
                            {/*<li className="dropdown-header">*/}
                                {/*You have 4 new notifications*/}
                                {/*<a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="notification-item">*/}
                                {/*<i className="bi bi-exclamation-circle text-warning"></i>*/}
                                {/*<div>*/}
                                    {/*<h4>Lorem Ipsum</h4>*/}
                                    {/*<p>Quae dolorem earum veritatis oditseno</p>*/}
                                    {/*<p>30 min. ago</p>*/}
                                {/*</div>*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="notification-item">*/}
                                {/*<i className="bi bi-x-circle text-danger"></i>*/}
                                {/*<div>*/}
                                    {/*<h4>Atque rerum nesciunt</h4>*/}
                                    {/*<p>Quae dolorem earum veritatis oditseno</p>*/}
                                    {/*<p>1 hr. ago</p>*/}
                                {/*</div>*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="notification-item">*/}
                                {/*<i className="bi bi-check-circle text-success"></i>*/}
                                {/*<div>*/}
                                    {/*<h4>Sit rerum fuga</h4>*/}
                                    {/*<p>Quae dolorem earum veritatis oditseno</p>*/}
                                    {/*<p>2 hrs. ago</p>*/}
                                {/*</div>*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="notification-item">*/}
                                {/*<i className="bi bi-info-circle text-primary"></i>*/}
                                {/*<div>*/}
                                    {/*<h4>Dicta reprehenderit</h4>*/}
                                    {/*<p>Quae dolorem earum veritatis oditseno</p>*/}
                                    {/*<p>4 hrs. ago</p>*/}
                                {/*</div>*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}
                            {/*<li className="dropdown-footer">*/}
                                {/*<a href="#">Show all notifications</a>*/}
                            {/*</li>*/}

                        {/*</ul>*/}

                    {/*</li>*/}

                    {/*<li className="nav-item dropdown">*/}

                        {/*<a className="nav-link nav-icon" href="#" data-bs-toggle="dropdown">*/}
                            {/*<i className="bi bi-chat-left-text"></i>*/}
                            {/*<span className="badge bg-success badge-number">3</span>*/}
                        {/*</a>*/}

                        {/*<ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">*/}
                            {/*<li className="dropdown-header">*/}
                                {/*You have 3 new messages*/}
                                {/*<a href="#"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="message-item">*/}
                                {/*<a href="#">*/}
                                    {/*<img src={process.env.PUBLIC_URL + '/assets/img/messages-1.jpg'} alt="" className="rounded-circle" />*/}
                                    {/*<div>*/}
                                        {/*<h4>Maria Hudson</h4>*/}
                                        {/*<p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>*/}
                                        {/*<p>4 hrs. ago</p>*/}
                                    {/*</div>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="message-item">*/}
                                {/*<a href="#">*/}
                                    {/*<img src={process.env.PUBLIC_URL + '/assets/img/messages-2.jpg'} alt="" className="rounded-circle" />*/}
                                    {/*<div>*/}
                                        {/*<h4>Anna Nelson</h4>*/}
                                        {/*<p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>*/}
                                        {/*<p>6 hrs. ago</p>*/}
                                    {/*</div>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="message-item">*/}
                                {/*<a href="#">*/}
                                    {/*<img src={process.env.PUBLIC_URL + '/assets/img/messages-3.jpg'} alt="" className="rounded-circle" />*/}
                                    {/*<div>*/}
                                        {/*<h4>David Muldon</h4>*/}
                                        {/*<p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>*/}
                                        {/*<p>8 hrs. ago</p>*/}
                                    {/*</div>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li className="dropdown-footer">*/}
                                {/*<a href="#">Show all messages</a>*/}
                            {/*</li>*/}

                        {/*</ul>*/}

                    {/*</li>*/}

                    <li className="nav-item dropdown pe-3">

                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            <img src="https://img.freepik.com/free-icon/user_318-159711.jpg" width="32" height="32" alt="John" className="shadow" />
                            <span className="d-none d-md-block dropdown-toggle ps-2">
                                {me && (<>{me.user.name}</>)}
                            </span>
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6>{me && (<>{me.user.name}</>)}</h6>
                                <span>Vendeur</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>

                            {/*<li>*/}
                                {/*<a className="dropdown-item d-flex align-items-center" href="users-profile.html">*/}
                                    {/*<i className="bi bi-person"></i>*/}
                                    {/*<span>My Profile</span>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<a className="dropdown-item d-flex align-items-center" href="users-profile.html">*/}
                                    {/*<i className="bi bi-gear"></i>*/}
                                    {/*<span>Account Settings</span>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            {/*<li>*/}
                                {/*<a className="dropdown-item d-flex align-items-center" href="pages-faq.html">*/}
                                    {/*<i className="bi bi-question-circle"></i>*/}
                                    {/*<span>Need Help?</span>*/}
                                {/*</a>*/}
                            {/*</li>*/}
                            {/*<li>*/}
                                {/*<hr className="dropdown-divider" />*/}
                            {/*</li>*/}

                            <li>
                                <button className="dropdown-item d-flex align-items-center text-danger" onClick={handleLogout}>
                                    <i className="bi bi-box-arrow-right"></i>
                                    <span>Se deconnecter</span>
                                </button>
                            </li>

                        </ul>
                    </li>

                </ul>
            </nav>

        </header>
    );
};

export default Customer_header;