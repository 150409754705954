import React, { useEffect, useState } from 'react';
import {useUser} from "../../../Services/userProvider";
import PrintComponent from "./printer";
import {toast} from "react-toastify";
import FretAPI from "../../../apis/fretAgentApi/FretAPI";
import Pagination from "../../assets/Pagination";
import TableLoader from "../../loaders/TableLoader";
import Moment from "moment/moment";
import VendeurAPI from "../../../apis/customerApi/VendeurAPI";

const DailyFrets = () => {
    const [showLoader, setShowLoader] = useState(false);

    const [totalUnaccompanied, setTotalUnAccompanied] = useState("");
    const [totalAccompanied, setTotalAccompanied] = useState("");
    const [dailyAccompaniedFret, setDailyAccopaniedFrest] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [setting, setSetting] = useState([]);

    const [dailyUncompaniedFrets, setDailyUnAccompaniedFret] = useState([]);
    const [startAt, setStartAt] = useState("");
    const [endAt, setEndAt] = useState("");

    const fetchSetting = async () => {
        try{
            const data =  await FretAPI.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };

    const getDailyAccompagnedFrets = async () => {
        try{
            const data =  await FretAPI.dailyAccompagneFrets();
            setDailyAccopaniedFrest(data.frets);
            setTotalAccompanied(data.total);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des frets du jours !");
        }
    };

    const dailyUnAccompagneFrets = async () => {
        try{
            const data =  await FretAPI.dailyUnAccompagneFrets();
            setDailyUnAccompaniedFret(data.frets);
            setTotalUnAccompanied(data.total);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des frets du jours !");
        }
    };



    useEffect(() => {
        getDailyAccompagnedFrets();
        dailyUnAccompagneFrets();
        fetchSetting();
    },[]);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 450;

    //Filtrage des buses en fonction de la recherche

    const filteredAccompaniedFrets = dailyAccompaniedFret.filter(
        b =>
            b.customer.toLowerCase().includes(search.toLowerCase()) ||
            b.recipient.toLowerCase().includes(search.toLowerCase()) ||
            b.rec_phone.toLowerCase().includes(search.toLowerCase()) ||
            b.phone.toLowerCase().includes(search.toLowerCase()) ||
            b.city_to.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_plaque.toLowerCase().includes(search.toLowerCase())
    );

    const filteredFretsUnAccompagned = dailyUncompaniedFrets.filter(
        b =>
            b.customer.toLowerCase().includes(search.toLowerCase()) ||
            b.recipient.toLowerCase().includes(search.toLowerCase()) ||
            b.rec_phone.toLowerCase().includes(search.toLowerCase()) ||
            b.phone.toLowerCase().includes(search.toLowerCase()) ||
            b.city_to.toLowerCase().includes(search.toLowerCase()) ||
            b.bus_plaque.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginateAccompaniedFret = Pagination.getData(
        filteredAccompaniedFrets,
        currentPage,
        itemsPerPage
    );

    const paginateUnAccompaniedFret = Pagination.getData(
        filteredFretsUnAccompagned,
        currentPage,
        itemsPerPage
    );


    let counter = 1;
    let counter2 = 1;

    function calculTotal(data) {
        let tCDF = 0;
        data.map((toto) => toto.fret_items.map((item) => tCDF += item.amount));
        return tCDF
    }

    function calculSubTotal(data){
        let tCDF = 0;
        data.fret_items.map((item) => tCDF += item.amount);
        return tCDF
    }

    const handleStartedDate = ({currentTarget}) => {
        setStartAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleEndedDate = ({currentTarget}) => {
        setEndAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleBtns = () => {

        if(startAt !== "" && endAt === "") {
            const filtered = dailyAccompaniedFret.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") === Moment(startAt).format("YYYY/MM/DD"));
            setDailyAccopaniedFrest(filtered);
        } else if (startAt !== "" && endAt !== "") {
            const filtered = dailyAccompaniedFret.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD")
                    && Moment(item.created_at).format("YYYY/MM/DD") <= Moment(endAt).format("YYYY/MM/DD"));
            setDailyAccopaniedFrest(filtered);
        }else{
            const filtered = dailyAccompaniedFret.filter(item=>
                Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD"));
            setDailyAccopaniedFrest(filtered)
        }

    };

    const  handleAddToManifest = async (id) => {
        try{

            setShowLoader(true);
            await VendeurAPI.addToManifest(id);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Opération reussie avec succès");
            window.location.reload();
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {message} = response.data;
            toast.error(message);
        }
    };


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">
                                Total :
                                <b className="text-success fw-bold ms-3">
                                    {paginateAccompaniedFret.length}
                                </b> clients
                            </h2>

                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Recherche par expediteur ou destinataire
                                        </label>
                                        <input type="text"
                                               onChange={handleSearch}
                                               value={search}
                                               name="ByAll"
                                               id="ByAll"
                                               className="form-control"
                                               placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Date debut
                                        </label>
                                        <input type="date" onChange={handleStartedDate} value={startAt}
                                               name="startDate" id="startDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="endDate" className="text-dark">
                                            Date fin
                                        </label>
                                        <input type="date" onChange={handleEndedDate} value={endAt}
                                               name="endDate" id="endDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="filter" className="text-dark">.</label>
                                        <input type="button" onClick={handleBtns} value="Filtrer"
                                               name="filter" id="filter" className="form-control btn btn-dark" placeholder="Filtrer ..."
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {/*<div className="col-md-6">*/}
                                {/*    <h4 className="fw-bold text-success">*/}
                                {/*        Accompagné :  <b className="text-primary fw-bold ms-3">*/}
                                {/*        {paginateAccompaniedFret && calculTotal(paginateAccompaniedFret)} CDF*/}
                                {/*    </b>*/}
                                {/*    </h4>*/}
                                {/*    <table className="table table-hover table-striped">*/}
                                {/*        <thead className="bg-success-light">*/}
                                {/*        <tr>*/}
                                {/*            <th scope="col">N°</th>*/}
                                {/*            <th scope="col">Exp</th>*/}
                                {/*            <th scope="col">Montant</th>*/}
                                {/*            <th scope="col">Colis</th>*/}
                                {/*            <th scope="col">Destinateur</th>*/}
                                {/*            <th scope="col">Options</th>*/}
                                {/*        </tr>*/}
                                {/*        </thead>*/}
                                {/*        {!loading && <tbody>*/}
                                {/*        {paginateAccompaniedFret.map((fret) => (*/}
                                {/*            calculSubTotal(fret) > 0 &&*/}
                                {/*            (<tr key={fret.id}>*/}
                                {/*                <th scope="row">{counter++}</th>*/}
                                {/*                <td><b>{fret.customer}</b> <br/> {fret.phone}<br/> {fret.code}</td>*/}
                                {/*                <td><b>{calculSubTotal(fret)}</b> CDF</td>*/}
                                {/*                /!*<td>{Moment(fret.created_at).format("DD/MM/YYYY")}</td>*!/*/}
                                {/*                <td>*/}
                                {/*                    <ul>*/}
                                {/*                        {*/}
                                {/*                            fret.fret_items.map((p) => (*/}
                                {/*                                <li key={p.id}>*/}
                                {/*                                    {p.weight} {p.name} : ({p.amount} FC)*/}
                                {/*                                    {!p.accompanied && <button*/}
                                {/*                                        title="Ajouter au manifeste"*/}
                                {/*                                        className="btn btn-sm btn-success"*/}
                                {/*                                        onClick={() => {*/}
                                {/*                                            if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {*/}
                                {/*                                                handleAddToManifest(p.id)*/}
                                {/*                                            }*/}
                                {/*                                        }}*/}
                                {/*                                    >*/}
                                {/*                                        Envoyer au manifeste*/}
                                {/*                                    </button>}*/}
                                {/*                                </li>*/}
                                {/*                            ))*/}
                                {/*                        }*/}
                                {/*                    </ul>*/}
                                {/*                </td>*/}
                                {/*                <td><b>{fret.recipient}</b> <br/> {fret.rec_phone}<br/> {fret.city_to}</td>*/}
                                {/*                <td>*/}
                                {/*                    <div className="btn-group">*/}
                                {/*                        <button title="Imprimer" className="btn btn-primary">*/}
                                {/*                            <PrintComponent setting={setting} fret={fret} />*/}
                                {/*                        </button>*/}
                                {/*                    </div>*/}
                                {/*                </td>*/}
                                {/*            </tr>)*/}
                                {/*        ))*/}
                                {/*        }*/}

                                {/*        </tbody>}*/}
                                {/*    </table>*/}
                                {/*    {loading && <TableLoader width={300} height={350} />}*/}

                                {/*    {itemsPerPage < filteredAccompaniedFrets.length &&*/}
                                {/*        <Pagination*/}
                                {/*            currentPage={currentPage}*/}
                                {/*            itemsPerPage={itemsPerPage}*/}
                                {/*            length={filteredAccompaniedFrets.length}*/}
                                {/*            onPageChange={handleChangeCurrentPage}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <div className="col-md-12">
                                    <h4 className="fw-bold text-dark">
                                        Net du jour :  <b className="text-primary fw-bold ms-3">
                                        {paginateUnAccompaniedFret && calculTotal(paginateUnAccompaniedFret)} CDF
                                    </b>
                                    </h4>
                                    <table className="table table-hover table-striped">
                                        <thead className="bg-dark-light">
                                        <tr>
                                            <th scope="col">N°</th>
                                            <th scope="col">Code</th>
                                            <th scope="col">Exp</th>
                                            <th scope="col">Montant</th>
                                            <th scope="col">Colis</th>
                                            <th scope="col">Destinateur</th>
                                            <th scope="col">Vers</th>
                                            <th scope="col">Options</th>
                                        </tr>
                                        </thead>
                                        {!loading && <tbody>
                                        {paginateUnAccompaniedFret.map((fret) => (
                                            calculSubTotal(fret) > 0 &&
                                            (<tr key={fret.id}>
                                                <th scope="row">{counter2++}</th>
                                                <td><b className="text-primary">{fret.code}</b></td>
                                                <td><b>{fret.customer}</b> <br/> {fret.phone}</td>
                                                <td><b>{calculSubTotal(fret)}</b> CDF</td>
                                                {/*<td>{Moment(fret.created_at).format("DD/MM/YYYY")}</td>*/}
                                                <td>
                                                    <ul>
                                                        {
                                                            fret.fret_items.map((p) => (
                                                                <li key={p.id}>
                                                                    {p.weight} {p.name} : ({p.amount} FC)
                                                                    {!p.accompanied && <button
                                                                        title="Ajouter au manifeste"
                                                                        className="btn btn-sm btn-success"
                                                                        onClick={() => {
                                                                            if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                                handleAddToManifest(p.id)
                                                                            }
                                                                        }}
                                                                    >
                                                                        Envoyer au manifeste
                                                                    </button>}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </td>
                                                <td><b>{fret.recipient}</b> <br/> {fret.rec_phone}</td>
                                                <td>{fret.city_to}</td>
                                                <td>
                                                    <div className="btn-group">
                                                        <button title="Imprimer" className="btn btn-primary">
                                                            <PrintComponent setting={setting} fret={fret} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>)
                                        ))
                                        }

                                        </tbody>}
                                    </table>

                                    {loading && <TableLoader width={300} height={350} />}

                                    {itemsPerPage < filteredFretsUnAccompagned.length &&
                                        <Pagination
                                            currentPage={currentPage}
                                            itemsPerPage={itemsPerPage}
                                            length={filteredFretsUnAccompagned.length}
                                            onPageChange={handleChangeCurrentPage}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DailyFrets;
