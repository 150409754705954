import React, { useState, useContext } from 'react';
import {Link, useNavigate} from "react-router-dom";
import AuthAPI from "../../../Services/auth/authAPI";
import {toast} from "react-toastify";
import AuthProvider from "../../../context/AuthProvider";
import Field from "../../forms/Field";
import CheckBoxField from "../../forms/CheckBoxField";
import {useForm} from "react-hook-form";
import MyButton from "../../assets/MyButton";

const LoginPage = () => {

    const { formState } = useForm();
    const { isSubmitting } = formState;

    const { setAuth } = useContext(AuthProvider);
    const navigate = useNavigate();

    const [isChecked, setIsChecked] = useState(false);

    const [credentials, setCredentials] = useState({
        username: "",
        password: "",
        rememberMe: false
    });

    const handleCheckBoxOnChange = () => {
        setIsChecked(!isChecked);
        setCredentials({...credentials, rememberMe: !isChecked});
    };

    const [error, setError] = useState("");

    const [showLoader, setShowLoader] = useState(false);




    //Gestion du submit
    const handleSubmit = async event => {
        event.preventDefault();

        try{
            setShowLoader(true);
            await AuthAPI.authenticate(credentials);
            setTimeout(() => setShowLoader(false), 1000);
            setError("");
            setAuth(true);
            toast.success("Vous être connecté");
            const userRole = AuthAPI.getUserRoles();
            if (userRole === "ROLE_ADMIN") {
                navigate("/admin/");
            } else if (userRole === "ROLE_VENDOR") {
                navigate("/customer/");
            } else if (userRole === "ROLE_MANAGER") {
                navigate("/manager/");
            } else if (userRole === "ROLE_FRET") {
                navigate("/fret/");
            } else if (userRole === "ROLE_MANIFEST") {
                navigate("/manifest/");
            }else if (userRole === "ROLE_DISPATCHER") {
                navigate("/dispatcher");
            }else if (userRole === "ROLE_MAINTAINER") {
                navigate("/maintainer");
            } else {
                navigate("/", { replace: true });
            }
        }catch(error){
            setTimeout(() => setShowLoader(false), 1000);
            setError("Les informations ne correspondent pas !");
            toast.error("Une erreur est servenue!");
        }
    };



    //Gestion des champs
    const handleChange = ({currentTarget}) => {
        const {value, name} = currentTarget;

        setCredentials({...credentials, [name]: value});
    };





    return (
        <main>
            <div className="container">

                <section
                    className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div
                                className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div className="d-flex justify-content-center py-4">
                                    <Link to="/" className="logo d-flex align-items-center w-auto">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt=""/>
                                            <span className="d-none d-lg-block">KKS EXPRESS</span>
                                    </Link>
                                </div>

                                <div className="card mb-3">

                                    <div className="card-body">

                                        <div className="pt-4 pb-2">
                                            <h5 className="card-title text-center pb-0 fs-4">Connexion</h5>
                                            <p className="text-center small">Entrez votre e-mail et votre mot de passe pour vous connecter</p>
                                        </div>


                                        <form onSubmit={handleSubmit} className="row g-3 needs-validation">

                                            <div className="col-12">
                                                <Field
                                                    label="Téléphone ou email"
                                                    placeholder="Téléphone ou email"
                                                    value={credentials.username}
                                                    onChange={handleChange}
                                                    name="username"
                                                    type="text"
                                                    autoComplete="true"
                                                    error=""
                                                />
                                            </div>

                                            <div className="col-12">
                                                <Field
                                                    label="Votre mot de passe"
                                                    placeholder="Mot de passe"
                                                    value={credentials.password}
                                                    onChange={handleChange}
                                                    name="password"
                                                    type="password"
                                                    error={error}
                                                />
                                            </div>

                                            <div className="col-6">
                                                <CheckBoxField
                                                    label="Se souvenir de moi"
                                                    value={credentials.rememberMe}
                                                    onChange={handleCheckBoxOnChange}
                                                    name="rememberMe"
                                                    checked={isChecked}
                                                />
                                            </div>
                                            <div className="col-6">
                                                {/*<Link to="/forgot-password">Mot de passe oublié?</Link>*/}
                                            </div>
                                            <div className="col-12">
                                                {/*<button disabled={isSubmitting} className="btn btn-primary w-100">*/}
                                                    {/*{isSubmitting &&*/}
                                                    {/*<><span className="spinner-border spinner-border-sm" role="status"*/}
                                                            {/*aria-hidden="true"></span>...</>*/}
                                                    {/*} Se connecter*/}
                                                {/*</button>*/}
                                                <MyButton
                                                    text="Connexion"
                                                    onSubmit={handleSubmit}
                                                    loading={showLoader}
                                                    disabled={showLoader}
                                                />
                                                {/*<button onClick={() => login()} className="btn btn-primary w-100" type="button"></button>*/}
                                            </div>
                                            {/*<div className="col-12">*/}
                                                {/*<p className="small mb-0">Vous n'avez pas de compte? <Link*/}
                                                    {/*to="/register">Créer un compte</Link></p>*/}
                                            {/*</div>*/}
                                        </form>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </main>
    );
};

export default LoginPage;
