export const API_URL = "https://api.kksenterprise.com/api/v1/";
//export const API_URL = "https://api.zar-africa.co.za/api/v1/";
//export const API_URL = "http://localhost:8000/api/v1/";
//export const API_URL = "http://192.168.36.232:8000/api/v1/";

export const LOGIN_API = API_URL + "login";



/**
 * ADMIN APIs
 * @type {string}
 */


export const ADMIN_API =  API_URL + "admin";
export const BUSES_API =  API_URL + "admin/buses";
export const BUS_DOC_API =  API_URL + "admin/documents";
export const BUS_BUS_DOC_API =  API_URL + "admin/bus_documents";
export const BRAND_API =  API_URL + "admin/brands";
export const UNITS_API =  API_URL + "admin/units";
export const STORES_API =  API_URL + "admin/stores";
export const REQUESTS_API =  API_URL + "admin/requests";
export const MVT_API =  API_URL + "admin/movements";

export const BUSESHOURS_API =  API_URL + "admin/busesHours";

export const TRAJETS_API =  API_URL + "admin/trajets";

export const USERS_API =  API_URL + "admin/users";

export const PVS_API =  API_URL + "admin/pvs";

export const AGENCY_API =  API_URL + "admin/agencies";

export const BIELLETERIE_API =  API_URL + "admin/rapports/billeterie";

export const FRET_API =  API_URL + "admin/rapports/frets";

export const FRET_CATALOGUES_API =  API_URL + "admin/fretCatalogues";

export const SETTING_API =  API_URL + "admin/settings";

/**
 * MANAGER APIs
 */

export const MANAGER_BUSES_API =  API_URL + "manager";


/**
 * DISPATCHER APIs
 */

export const DISPATCHER_API =  API_URL + "dispatcher";

/**
 * MAINTAINER APIs
 */

export const MAINTAINER_API =  API_URL + "maintainer";



/**
 * VENDOR APIs
 */

export const VENDOR_SETTING_API =  API_URL + "vendor/settings";

export const VENDOR_POS_API =  API_URL + "vendor";

export const VENDOR_BUSES_API =  API_URL + "vendor/buses";


/**
 * FERT APIs
 */

export const FRET_SETTING_API =  API_URL + "fret/settings";

export const FRET_POS_API =  API_URL + "fret";

export const FRET_BUSES_API =  API_URL + "fret/buses";

/**
 * MANIFEST APIs
 */

export const MANIFEST_SETTING_API =  API_URL + "manifest/settings";

export const MANIFEST_POS_API =  API_URL + "manifest";

export const MANIFEST_BUSES_API =  API_URL + "manifest/buses";
