import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import {toast} from "react-toastify";
import BielletterieAPI from "../../../apis/adminApi/bielleterieAPI";
import PvsAPI from "../../../apis/adminApi/posAPI";
import SettingAPI from "../../../apis/adminApi/settingAPI";
import Pagination from "../../assets/Pagination";
import TableLoader from "../../loaders/TableLoader";
import PrintComponent from "./printer";
import FretsAPI from "../../../apis/adminApi/fretAPI";
import {Link, useParams} from "react-router-dom";


const RapportDailyBilletterie = () => {

    let {city} = useParams();
    const [total, setTotal] = useState();
    const [allTickets, setDailyTickets] = useState([]);
    const [pvs, setpvs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [setting, setSetting] = useState([]);
    const [loading, setLoading] = useState(true);
    const [vivo, setOutPutData] = useState([]);
    const [dailySpents, setDailyBusSpents] = useState([]);
    const [allFrets, setAllFret] = useState([]);

    const fetchSetting = async () => {
        try{
            const data =  await SettingAPI.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };

    const getAllDailySpents = async () => {
        try{
            const data =  await BielletterieAPI.dailySpents();
            setDailyBusSpents(data.spents);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des tickets !");
        }
    };

    const fetchAllTickets = async () => {
        try{
            const data =  await BielletterieAPI.findDailyTickets();
            setLoading(false);
            setDailyTickets(data);
            setOutPutData(data);
        }catch(error){
            toast.error("Erreur lors du chargement des tickets !");
        }
    };

    const fetchPvs = async () => {
        try{
            const data =  await PvsAPI.findAll();
            setLoading(false);
            setpvs(data);
        }catch(error){
            toast.error("Erreur lors du chargement des pvs !");
        }
    };


    const fetchDailyFrets = async () => {
        try{
            const data =  await FretsAPI.findDailyFrets();
            setAllFret(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des tickets frets !");
        }
    };

    //Au chargement du composant on va chercher les buses
    useEffect(() => {
        fetchAllTickets();
        fetchPvs();
        fetchDailyFrets();
        getAllDailySpents();
        fetchSetting();
    }, []);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };


    const itemsPerPage = 99999999;

    const [startAt, setStartAt] = useState("");
    const [endAt, setEndAt] = useState("");

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const handleStartedDate = ({currentTarget}) => {
        setStartAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleEndedDate = ({currentTarget}) => {
        setEndAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleBtns = () => {

        if(startAt !== "" && endAt === "") {
            const filtered = allTickets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") === Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        } else if (startAt !== "" && endAt !== "") {
            const filtered = allTickets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD")
                    && Moment(item.created_at).format("YYYY/MM/DD") <= Moment(endAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        }else{
            const filtered = allTickets.filter(item=>
                Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered)
        }

    };

    const filteredAllTikets = vivo.filter(
        b =>
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.to.toLowerCase().includes(search.toLowerCase()) ||
            b.vendor_name.toLowerCase().includes(search.toLowerCase()) ||
            b.from.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedAllTikets = Pagination.getData(
        filteredAllTikets,
        currentPage,
        itemsPerPage
    );

    let counter = 1;

    function calculTotalFret(data) {
        let tCDF = 0;
        data.map((toto) => tCDF += toto.total_amount);
        return tCDF
    }

    function calculSubTotal(data) {
        let tCDF = 0;
        data.map((ticket) => tCDF += ticket.amount);
        return tCDF
    }

    function calculCDF(data) {
        let tCDF = 0;
        data.map((item) => tCDF += item.totalCDF);
        return tCDF
    }

    function calculUSD(data) {
        let tUSD = 0;
        data.map((item) => tUSD += item.totalUSD);
        return tUSD
    }

    function calculTotal(data) {
        let tCDF = 0;
        data.map((toto) => toto.tickets.map((ticket) => tCDF += ticket.amount));
        return tCDF
    }

    //Gestion de la suppression d'un invoice
    const handleDelete = async id => {

        try{
            await BielletterieAPI.deleteInvoice(id);
            toast.success("Le ticket a bien été supprimée !");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la suppression du tickets !");
        }
    };

    const handleDeleteColis= async id => {

        try{
            await BielletterieAPI.deleteColisTicket(id);
            toast.success("Les colis ont bien été supprimés !");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la suppression des colis !");
        }
    };

    const handleReprint = async id => {

        try{
            await BielletterieAPI.reprintTicket(id);
            toast.success("Le ticket peut etre de nouveau imprimé");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la mise à jour!");
        }
    };

    const handleDeleteFret = async id => {

        try{
            await BielletterieAPI.deleteFret(id);
            toast.success("le ticket a bien été supprimée !");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la suppression du tickets !");
        }
    };

    const ticketsByDestination = (data) => {
        const destinations = data.tickets.map((key, value) => key = key.destination);
        let data_table = [];
        destinations.forEach((val) => {
            if (data_table[val]) {
                data_table[val] += 1;
            } else {
                data_table[val] = 1;
            }
        });
        return data_table;
    };

    function calculTotalColis(data){
        let tCDF = 0;
        data.map((toto) => toto.tickets.map((ticket) => ticket.fret_items.map((colis) => tCDF += colis.amount)));
        return tCDF
    }

    function calculColisSubTotal(data){
        let tCDF = 0;
        data.map((ticket) => ticket.fret_items.map((colis) => tCDF += colis.amount));
        return tCDF
    }

    let conter = 1;

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-10">
                                    <h2 className="card-title">
                                        Total : <b className="text-primary">
                                        {paginatedAllTikets ? paginatedAllTikets.length : 0} </b> Bus
                                    </h2>

                                </div>
                            </div>

                            <div className="row">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <Link to="/admin/rapports/billetterie" className={!city ? "btn btn-warning" : "btn btn-outline-warning"}>Toutes les villes</Link>
                                    <Link to="/admin/rapports/dailybilletterieByCity/Lubumbashi" className={city==='Lubumbashi' ? "btn btn-primary" : "btn btn-outline-primary"}>Lubumbashi</Link>
                                    <Link to="/admin/rapports/dailybilletterieByCity/Likasi" className={city==='Likasi' ? "btn btn-success" : "btn btn-outline-success"}>Likasi</Link>
                                    <Link to="/admin/rapports/dailybilletterieByCity/Fungurume" className={city==='Fungurume' ? "btn btn-danger" : "btn btn-outline-danger"}>Fungurume</Link>
                                    <Link to="/admin/rapports/dailybilletterieByCity/Kolwezi" className={city==='Kolwezi' ? "btn btn-info" : "btn btn-outline-info"}>Kolwezi</Link>
                                    <Link to="/admin/rapports/dailybilletterieByCity/Kamoa" className={city==='Kamoa' ? "btn btn-info" : "btn btn-outline-info"}>Kamoa</Link>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Recherche par Point de vente, vendeur ou Bus
                                        </label>
                                        <input type="text" onChange={handleSearch} value={search}
                                               name="ByAll" id="ByAll" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Date debut
                                        </label>
                                        <input type="date" onChange={handleStartedDate} value={startAt}
                                               name="startDate" id="startDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="endDate" className="text-dark">
                                            Date fin
                                        </label>
                                        <input type="date" onChange={handleEndedDate} value={endAt}
                                               name="endDate" id="endDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="filter" className="text-dark">.</label>
                                        <input type="button" onClick={handleBtns} value="Filtrer"
                                               name="filter" id="filter" className="form-control btn btn-dark" placeholder="Filtrer ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-4 d-flex float-end">
                                        <PrintComponent type="billet" critere={search} data={filteredAllTikets} />
                                    </div>
                                </div>
                            </div>

                            <hr/>


                            {loading ? (<TableLoader width={300} height={350} />) :
                                (<ol className="list-group list-group-numbered">
                                {paginatedAllTikets.map((bus) => (
                                    <div key={bus.id}>
                                        <li className="list-group-item d-flex justify-content-between align-items-start">
                                        <div className="ms-2 me-auto">
                                            <div className="fw-bold">
                                                <span className="text-success">{bus.bus_numberplate}</span> |
                                                <span className="text-dark"> {bus.reservation === 1 ?
                                                    (<i className="text-info">Bus direct</i>)
                                                        :
                                                    (<i className="text-warning">Bus de transite</i>)
                                                            }
                                                </span>
                                                <small className="text-dark"> {bus.shared !== 0 &&
                                                    ( <i className="bi bi-share text-warning">Partagé</i>)}
                                                </small>
                                            </div>
                                            De : {bus.from}, vers : <b>{bus.to}</b>, Depart : {bus.bus_hour}<br/>
                                            <span className="">
                                                <b className="text-info">Tickets : {calculSubTotal(bus.tickets)} Fc + </b>
                                                        <b className="text-dark"> Colis : {calculColisSubTotal(bus.tickets)} Fc </b> -
                                            </span>
                                            <span>
                                                <b className="text-danger"> Dépense : {calculCDF(bus.bus_spents)} Fc + {calculUSD(bus.bus_spents)} $</b> =
                                                <b className="text-success"> {(calculSubTotal(bus.tickets) + calculColisSubTotal(bus.tickets)) - (calculCDF(bus.bus_spents) + ((calculUSD(bus.bus_spents) * setting.currency)))} Fc</b>
                                            </span>
                                            <br/>
                                            <small>
                                                <b className="text-danger">Le {Moment(bus.created_at).format("DD/MM/YYYY")}</b>,
                                                Vendeur {bus.vendor_name} <br/>
                                                Chauffeur : <b>{bus.driver_name}</b>, Escorteur : <b>{bus.manager_name}</b>
                                            </small>
                                            <div className="fw-bold">
                                                {ticketsByDestination(bus).Lubumbashi && (<span className="badge bg-primary mx-1">
                                                Lubumbashi : {ticketsByDestination(bus).Lubumbashi}</span>)}

                                                {ticketsByDestination(bus).Kolwezi && (<span className="badge bg-success mx-1">
                                                Kolwezi : {ticketsByDestination(bus).Kolwezi}</span>)}

                                                {ticketsByDestination(bus).Likasi && (<span className="badge bg-info mx-1">
                                                Likasi : {ticketsByDestination(bus).Likasi}</span>)}

                                                {ticketsByDestination(bus).Fungurume && (<span className="badge bg-dark mx-1">
                                                Fungurume : {ticketsByDestination(bus).Fungurume}</span>)}

                                                {ticketsByDestination(bus).Kasumbalesa && (<span className="badge bg-danger mx-1">
                                                Kasumbalesa : {ticketsByDestination(bus).Kasumbalesa}</span>)}

                                                {ticketsByDestination(bus).Sakania && (<span className="badge bg-primary mx-1">
                                                Sakania : {ticketsByDestination(bus).Sakania}</span>)}

                                                {ticketsByDestination(bus).Kamoa && (<span className="badge bg-primary mx-1">
                                                Kamoa : {ticketsByDestination(bus).Kamoa}</span>)}
                                            </div>
                                        </div>

                                        <span className="badge bg-primary rounded-pill mx-2">{bus.tickets.length} tickets / {bus.bus_number_of_seats}</span>
                                        <a className="badge bg-success" data-bs-toggle="collapse"
                                           href={"#tickets"+bus.id} role="button" aria-expanded="false"
                                           aria-controls={"tickets"+bus.id}>Voir <i className="bi bi-eye"></i> </a>
                                    </li>
                                        <div className="collapse multi-collapse" id={"tickets"+bus.id}>
                                            <div className="card card-body">
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <h4 className="text-primary">Frets</h4>
                                                        <table className="table table-hover table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col">Code</th>
                                                                <th scope="col">Designation</th>
                                                                <th scope="col">Nombre</th>
                                                                <th scope="col">Montant</th>
                                                                <th scope="col">Options</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {bus.colis.map((fret) => (
                                                                <tr key={fret.id}>
                                                                    <td><b>{fret.reference}</b></td>
                                                                    <td><b>{fret.name}</b></td>
                                                                    <td><b>{fret.code}</b></td>
                                                                    <td>{fret.amount} CDF</td>
                                                                    <td>
                                                                        <td>
                                                                            <button
                                                                                onClick={() => {
                                                                                    if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                                        handleDeleteFret(fret.id)
                                                                                    }
                                                                                }}
                                                                                className="btn btn-sm btn-danger">
                                                                                Sup
                                                                            </button>
                                                                        </td>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-md-7">
                                                        <h4 className="text-primary">Tickets</h4>
                                                        <table className="table table-hover table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Client</th>
                                                                <th scope="col">Dest</th>
                                                                <th scope="col">Montant</th>
                                                                <th scope="col">Bonus</th>
                                                                <th scope="col">Colis</th>
                                                                <th scope="col">Options</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {bus.tickets.map((ticket) => (
                                                                <tr key={ticket.id}>
                                                                    <td>{conter++}</td>
                                                                    <td><b>{ticket.customer}</b> <br/>
                                                                        <small>{ticket.bus_number}, <b>{ticket.bus_plaque}</b></small>
                                                                    </td>
                                                                    <td>{ticket.destination}</td>
                                                                    <td>{ticket.amount} CDF</td>
                                                                    <td>{ticket.bonus === 1 ? (<b className="badge text-bg-success">Oui</b>)
                                                                        :(<b className="badge text-bg-dark">Non</b>)}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            ticket.fret_items &&
                                                                            <>
                                                                                <ul>
                                                                                    {ticket.fret_items.map((coli) =>
                                                                                        <li style={{fontSize:"14px"}}>
                                                                                            {coli.weight} {coli.name} - {coli.amount} Fc
                                                                                        </li>
                                                                                    )}
                                                                                </ul>
                                                                            </>
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <div className="btn-group" role="group" aria-label="Basic example">
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                                        handleDelete(ticket.id)
                                                                                    }
                                                                                }}
                                                                                className="btn btn-sm btn-danger">
                                                                                Del
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                                        handleReprint(ticket.id)
                                                                                    }
                                                                                }}
                                                                                className={`btn btn-sm ${!ticket.printed ? 'btn-success' : 'btn-primary'}`}>
                                                                                Print
                                                                            </button>
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                                        handleDeleteColis(ticket.id)
                                                                                    }
                                                                                }}
                                                                                className={`btn btn-sm btn-outline-danger`}>
                                                                                Sup colis
                                                                            </button>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </ol>)
                            }
                        </div>

                        {itemsPerPage < filteredAllTikets.length &&
                        <Pagination
                            currentPage={currentPage}
                            itemsPerPage={itemsPerPage}
                            length={filteredAllTikets.length}
                            onPageChange={handleChangeCurrentPage}
                        />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RapportDailyBilletterie;