import React,{useState} from 'react';
import AuthService from "../../../Services/auth/authAPI";
import {Link, useNavigate, useParams} from "react-router-dom";

const DispatcherSideBar = ({pathname}) => {

    const id = useParams();

    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());

    const handleLogout = () => {
        AuthService.logout();
        navigate("/login")
    };

    return (
        <aside id="sidebar" className="sidebar">

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <Link className={pathname === '/dispatcher' ? 'nav-link ' : 'nav-link collapsed'} to="/dispatcher">
                        <i className="bi bi-grid"></i>
                        <span>Tableau de bord</span>
                    </Link>
                </li>


                <li className="nav-heading">Dispatch</li>

                <li className="nav-item">
                    <Link className={pathname === '/dispatcher/buses/addDispatcherBus' ? 'nav-link ' : 'nav-link collapsed'} to="/dispatcher/buses/addDispatcherBus">
                        <button className="btn btn-sm btn-primary" type="button">
                            <i className="bi bi-bus-front text-with"></i>
                            <span>Ajouter</span>
                        </button>
                    </Link>
                </li>


                <li className="nav-item">
                    <Link className={pathname === '/dispatcher/buses/dailyBuses' ? 'nav-link ' : 'nav-link collapsed'}
                          to="/dispatcher/buses/dailyBuses">
                        <i className="bi bi-joystick"></i>
                        <span>Bus du jour</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/dispatcher/buses/sheduledBuses' ? 'nav-link ' : 'nav-link collapsed'}
                          to="/dispatcher/buses/sheduledBuses">
                        <i className="bi bi-joystick"></i>
                        <span>Bus programmé de la semaine</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/dispatcher/buses/availablities' ? 'nav-link ' : 'nav-link collapsed'} to="/dispatcher/buses/availablities">
                        <i className="bi bi-joystick"></i>
                        <span>Tous les bus programmé</span>
                    </Link>
                </li>


                <li className="nav-heading">Agents</li>

                <li className="nav-item">
                    <Link className={pathname === '/dispatcher/drivers' ? 'nav-link ' : 'nav-link collapsed'} to="/dispatcher/drivers">
                        <i className="bi bi-person"></i>
                        <span>Chauffeurs</span>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link className={pathname === '/dispatcher/gerants' ? 'nav-link ' : 'nav-link collapsed'} to="/dispatcher/gerants">
                        <i className="bi bi-person"></i>
                        <span>Escorteurs</span>
                    </Link>
                </li>

            </ul>

        </aside>
    );
};

export default DispatcherSideBar;