import React, { useEffect, useState } from 'react';
import {Link,useParams} from "react-router-dom";
import Moment from 'moment';
import {toast} from "react-toastify";
import BielletterieAPI from "../../../apis/adminApi/bielleterieAPI";
import PvsAPI from "../../../apis/adminApi/posAPI";
import Pagination from "../../assets/Pagination";
import TableLoader from "../../loaders/TableLoader";
import PrintComponent from "./printer";
import TrajetsAPI from "../../../apis/adminApi/trajetsAPI";
import SettingAPI from "../../../apis/adminApi/settingAPI";


const RapportBilletterieByCity = () => {

    let {city} = useParams();
    const [total, setTotal] = useState();
    const [allTickets, setDailyTickets] = useState([]);
    const [pvs, setpvs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [searchByPos, setSearchByPos] = useState("");
    const [loading, setLoading] = useState(true);
    const [vivo, setOutPutData] = useState([]);
    const [setting, setSetting] = useState([]);
    const [allSpents, setAllSpents] = useState([]);
    const [trajets, setTrajet] = useState([]);



    const fetchSetting = async () => {
        try{
            const data =  await SettingAPI.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };

    const getAllSpents = async city => {
        try{
            const data =  await BielletterieAPI.allSpentsByCity(city);
            setAllSpents(data.spents);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des tickets !");
        }
    };

    const fetchTrajets = async () => {
        try{
            const data =  await TrajetsAPI.findAll();
            setTrajet(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des trajets !");
        }
    };



    const fetchAllTickets = async city => {
        try{
            const data =  await BielletterieAPI.findAllTicketsByCity(city);
            setDailyTickets(data);
            setOutPutData(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des tickets !");
        }
    };

    const fetchPvs = async () => {
        try{
            const data =  await PvsAPI.findAll();
            setLoading(false);
            setpvs(data);
        }catch(error){
            toast.error("Erreur lors du chargement des pvs !");
        }
    };


    //Au chargement du composant on va chercher les buses
    useEffect(() => {
        fetchAllTickets(city);
        fetchPvs();
        getAllSpents(city);
        fetchSetting();
        fetchTrajets()
    }, [city]);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };


    const itemsPerPage = 1000;

    const [startAt, setStartAt] = useState("");
    const [endAt, setEndAt] = useState("");

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const handleStartedDate = ({currentTarget}) => {
        setStartAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleEndedDate = ({currentTarget}) => {
        setEndAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleBtns = () => {

        if(startAt !== "" && endAt === "") {
            const filtered = allTickets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") === Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        } else if (startAt !== "" && endAt !== "") {
            const filtered = allTickets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD")
                    && Moment(item.created_at).format("YYYY/MM/DD") <= Moment(endAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        }else{
            const filtered = allTickets.filter(item=>
                Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered)
        }

    };

    const filteredAllTikets = vivo.filter(
        b =>
            b.bus_numberplate.toLowerCase().includes(search.toLowerCase()) ||
            b.to.toLowerCase().includes(search.toLowerCase()) ||
            b.vendor_name.toLowerCase().includes(search.toLowerCase()) ||
            b.from.toLowerCase().includes(search.toLowerCase())
    );



    //Pagination des données
    const paginatedAllTikets = Pagination.getData(
        filteredAllTikets,
        currentPage,
        itemsPerPage
    );

    let conti = 1;

    function calculTotalFret(data) {
        let tCDF = 0;
        data.map((t) =>
            {
                let sisto = t.weight - t.code;
                tCDF += t.amount
            }
        );
        return tCDF
    }

    function calculSubTotal(data) {
        let tCDF = 0;
        data.map((ticket) => tCDF += ticket.amount);
        return tCDF
    }

    function calculCDF(data) {
        let tCDF = 0;
        data.map((item) => tCDF += item.totalCDF);
        return tCDF
    }

    function calculCDFGeneral(data) {
        let tCDF = 0;
        data.map((item) => item.bus_spents.map((b) => tCDF += b.totalCDF));
        return tCDF
    }

    function calculUSD(data) {
        let tUSD = 0;
        data.map((item) => tUSD += item.totalUSD);
        return tUSD
    }

    function calculUSDGeneral(data) {
        let tUSD = 0;
        data.map((item) => item.bus_spents.map((p) => tUSD += p.totalUSD ));
        return tUSD
    }




    const ticketsByDestination = (data) => {
        const destinations = data.tickets.map((key, value) => key = key.destination);
        let data_table = [];
        destinations.forEach((val) => {
            if (data_table[val]) {
                data_table[val] += 1;
            } else {
                data_table[val] = 1;
            }
        });
        return data_table;
    };

    function calculSubTotalGeneral(data) {
        let tCDF = 0;
        data.map((t) => t.tickets.map((r) => tCDF += r.amount));
        return tCDF
    }

    function calculTotalFretGeneral(data) {
        let tCDF = 0;
        data.map((t) =>
            t.colis.map((v) => {
                let sisto = v.weight - v.code;
                tCDF += v.amount
            })
        );
        return tCDF
    }

    function calculDailySpentGeneral(data) {
        let tUSD = 0;
        let tCDF = 0;
        data.map((spent) => tCDF += spent.totalCDF);
        data.map((spent) => tUSD += spent.totalUSD);
        return {tCDF, tUSD}
    }


    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className="card-title">
                                        RAPPORT GENERAL : <br/>
                                        <small>Entrée billet :</small>
                                        <b className="text-success">{calculSubTotalGeneral(paginatedAllTikets)} Fc</b>
                                        <br/>
                                        <small>Entrée Fret :</small>
                                        <b className="text-success">{calculTotalFretGeneral(paginatedAllTikets)} Fc</b>
                                        <small>Depense de bus :</small>
                                        <b className="text-danger">{calculCDFGeneral(paginatedAllTikets) + ((calculUSDGeneral(paginatedAllTikets) * setting.currency))} Fc</b>
                                        <br/>
                                        <hr/>
                                        <small>Net :</small>
                                        <b className="text-success">{(calculSubTotalGeneral(paginatedAllTikets) + calculTotalFretGeneral(paginatedAllTikets)) -
                                        (calculCDFGeneral(paginatedAllTikets) + ((calculUSDGeneral(paginatedAllTikets) * setting.currency)))} Fc</b>
                                    </h2>
                                </div>
                            </div>

                            <div className="row">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <Link to="/admin/rapports/billetterie" className={!city ? "btn btn-warning" : "btn btn-outline-warning"}>Toutes les villes</Link>
                                    <Link to="/admin/rapports/billetterieByCity/Lubumbashi" className={city==='Lubumbashi' ? "btn btn-primary" : "btn btn-outline-primary"}>Lubumbashi</Link>
                                    <Link to="/admin/rapports/billetterieByCity/Likasi" className={city==='Likasi' ? "btn btn-success" : "btn btn-outline-success"}>Likasi</Link>
                                    <Link to="/admin/rapports/billetterieByCity/Fungurume" className={city==='Fungurume' ? "btn btn-danger" : "btn btn-outline-danger"}>Fungurume</Link>
                                    <Link to="/admin/rapports/billetterieByCity/Kolwezi" className={city==='Kolwezi' ? "btn btn-info" : "btn btn-outline-info"}>Kolwezi</Link>
                                    <Link to="/admin/rapports/billetterieByCity/Kamoa" className={city==='Kamoa' ? "btn btn-info" : "btn btn-outline-info"}>Kamoa</Link>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Recherche par bus
                                        </label>
                                        <input type="text" onChange={handleSearch} value={search}
                                               name="ByAll" id="ByAll" className="form-control"
                                               placeholder="Rechercher ..."
                                               autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Date debut
                                        </label>
                                        <input type="date" onChange={handleStartedDate} value={startAt}
                                               name="startDate" id="startDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group mt-3">
                                        <label htmlFor="endDate" className="text-dark">
                                            Date fin
                                        </label>
                                        <input type="date" onChange={handleEndedDate} value={endAt}
                                               name="endDate" id="endDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="filter" className="text-dark">.</label>
                                        <input type="button" onClick={handleBtns} value="Filtrer"
                                               name="filter" id="filter" className="form-control btn btn-dark" placeholder="Filtrer ..."
                                        />
                                    </div>
                                </div>

                                {/*<div className="col-md-2">*/}
                                {/*<div className="form-group mt-4 d-flex float-end">*/}
                                {/*<PrintComponent type="billet" critere={search} data={filteredAllTikets} />*/}
                                {/*</div>*/}
                                {/*</div>*/}
                            </div>

                            <hr/>

                            <table className="table table-sm table-striped table-hover table-bordered">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Bus</th>
                                    <th scope="col">Agents</th>
                                    <th scope="col">Passagers</th>
                                    <th scope="col">Type</th>
                                    <th scope="col" className="table-primary">Entrée billets</th>
                                    <th scope="col" className="table-primary">Entrée Fret</th>
                                    <th scope="col" className="table-danger">Depense bus</th>
                                    <th scope="col" className="table-success">Benefice net</th>
                                </tr>
                                </thead>
                                {loading ? <TableLoader width={300} height={350}/> : <tbody>
                                <tr>
                                    <th>0</th>
                                    <td colSpan="5"><b>Total général : </b></td>
                                    <td className="text-primary centered">
                                        <b>{calculSubTotalGeneral(paginatedAllTikets)} Fc</b></td>
                                    <td className="text-primary"><b>{calculTotalFretGeneral(paginatedAllTikets)} Fc</b>
                                    </td>
                                    <td className="text-danger">
                                        <b>{calculCDFGeneral(paginatedAllTikets) + ((calculUSDGeneral(paginatedAllTikets) * setting.currency))} Fc</b>
                                    </td>
                                    <td className="text-success">
                                        <b>{(calculSubTotalGeneral(paginatedAllTikets) + calculTotalFretGeneral(paginatedAllTikets)) -
                                        (calculCDFGeneral(paginatedAllTikets) + ((calculUSDGeneral(paginatedAllTikets) * setting.currency)))} Fc</b>
                                    </td>
                                </tr>
                                {paginatedAllTikets.map((bus) => (
                                    <tr key={bus.id}>
                                        <th scope="row">{conti++}</th>
                                        <td><small>{Moment(bus.created_at).format("DD/MM/YYYY")}</small></td>
                                        <td>
                                            <b>{bus.bus_numberplate}</b> <br/>
                                            <small>{bus.from} - {bus.to}</small>
                                            <br/>
                                            <small>Depart : {bus.bus_hour}</small>
                                        </td>
                                        <td>Chauf : <b>{bus.driver_name}</b> <br/> Escor : <b>{bus.manager_name}</b></td>
                                        <td>
                                            <ol className="list-group list-group-numbered">
                                                {ticketsByDestination(bus).Lubumbashi && (
                                                    <li className="list-group-item align-items-start">
                                                        Lubumbashi : <b>{ticketsByDestination(bus).Lubumbashi}</b>
                                                    </li>)}

                                                {ticketsByDestination(bus).Kolwezi && (
                                                    <li className="list-group-item align-items-start">
                                                        Kolwezi : <b>{ticketsByDestination(bus).Kolwezi}</b></li>)}

                                                {ticketsByDestination(bus).Likasi && (
                                                    <li className="list-group-item align-items-start">
                                                        Likasi : <b>{ticketsByDestination(bus).Likasi}</b></li>)}

                                                {ticketsByDestination(bus).Fungurume && (
                                                    <li className="list-group-item align-items-start">
                                                        Fungurume : <b>{ticketsByDestination(bus).Fungurume}</b></li>)}

                                                {ticketsByDestination(bus).Kasumbalesa && (
                                                    <li className="list-group-item align-items-start">
                                                        Kasumbalesa : <b>{ticketsByDestination(bus).Kasumbalesa}</b>
                                                    </li>)}

                                                {ticketsByDestination(bus).Sakania && (
                                                    <li className="list-group-item align-items-start">
                                                        Sakania : <b>{ticketsByDestination(bus).Sakania}</b></li>)}
                                            </ol>
                                        </td>
                                        <td><span className="text-dark"> {bus.reservation === 1 ?
                                            (<i className="text-info">direct</i>)
                                            :
                                            (<i className="text-warning">transite</i>)
                                        }
                                                </span></td>
                                        <td className="table-primary text-success">
                                            <b>{calculSubTotal(bus.tickets)} Fc</b></td>
                                        <td className="table-primary text-success">
                                            <b>{calculTotalFret(bus.colis)} Fc</b></td>
                                        <td className="table-danger text-danger">
                                            <b>{calculCDF(bus.bus_spents) + ((calculUSD(bus.bus_spents) * setting.currency))} Fc</b>
                                        </td>
                                        <td className="table-success text-success">
                                            <b>{(calculSubTotal(bus.tickets)) -
                                            (calculCDF(bus.bus_spents) + ((calculUSD(bus.bus_spents) * setting.currency)))} Fc</b>
                                        </td>
                                    </tr>
                                ))}

                                </tbody>}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RapportBilletterieByCity;