import React, { useEffect, useState } from 'react';

import {useForm} from "react-hook-form";
import PrintComponent from "./printer";
import TableRows from "./tableRows";
import {toast} from "react-toastify";
import FretAPI from "../../../apis/fretAgentApi/FretAPI";
import Field from "../../forms/Field";
import Select from "../../forms/Select";
import Pagination from "../../assets/Pagination";
import {useNavigate} from "react-router-dom";
import MyButton from "../../assets/MyButton";

const NewFret = () => {

    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);

    const { formState } = useForm();
    const { isSubmitting } = formState;
    const [loading, setLoading] = useState(true);
    const [trajets, setTrajets] = useState([]);
    const [catalogues, setCatalogues] = useState([]);
    const [total, setTotal] = useState("");
    const [dailyTickets, setDailyTickets] = useState([]);
    const [buses, setBuses] = useState([]);


    const [errors, setErrors] = useState({
        customer : "", destination : "", phone : "", recipient : "", rec_phone : ""
    });

    const [rowsData, setRowsData] = useState([]);
    const [tableColi, setTableColis] = useState([]);

    const getTrajets = async () => {
        try{
            const data =  await FretAPI.getTrajets();
            setTrajets(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des trajets !");
        }
    };


    const getCatalogues = async () => {
        try{
            const data =  await FretAPI.fretCatalogues();
            setCatalogues(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des catalogues !");
        }
    };


    const getDailyFrets = async () => {
        try{
            const data =  await FretAPI.dailyFrets();
            setDailyTickets(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des ventes du jours !");
        }
    };

    useEffect(() => {
        getTrajets();
        getCatalogues();
        getDailyFrets();
    },[]);


    const addTableRows = ()=>{

        const rowsInput={
            colisName:'', qty: '', price: '', brittle: '', accompanied: ''
        };
        setRowsData([...rowsData, rowsInput])

    };
    const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
    };

    const [fret, setFret] = useState({
        customer : "", destination : "", phone : "", recipient : "", rec_phone : "",lesColis : rowsData
    });

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        setFret({...fret, [name] : value});
    };

    const handleForChange = (index, event) => {
        const { name, value } = event.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
        setFret({...fret, lesColis : rowsData});
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try{

            setShowLoader(true);
            await FretAPI.storeFret(fret);
            setTimeout(() => setShowLoader(false), 1000);
            toast.success("Fret ajouté avec succès");
            navigate('/fret/dailyFrets')
        }catch({response}){
            setTimeout(() => setShowLoader(false), 1000);
            const {violations} = response.data;

            if(violations){
                const apiErrors = {};
                Object.keys(violations).forEach(key => {
                    apiErrors[key] = violations[key]
                });

                setErrors(apiErrors);
                toast.error("Une erreur est servenue dans le formulaire d'ajout de billet !");
            }
        }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");

    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    //Filtrage des buses en fonction de la recherche

    const filteredCatalogues = catalogues.filter(
        b =>
            b.nomination.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedCatalogues = Pagination.getData(
        filteredCatalogues,
        currentPage,
        itemsPerPage
    );


    let counter = 1;



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-11">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={handleSubmit}>
                            <h5 className="card-title">Service Fret</h5>
                                <div className="row">
                                    <div className="col-md-4">
                                        <Field
                                            name="customer"
                                            id="customer"
                                            error={errors.customer}
                                            value={fret.customer}
                                            onChange={(e) => handleChange("customer", e)}
                                            label="Expéditeur"
                                            placeholder="Non..."
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="col-md-4">

                                        <Field
                                            name="phone"
                                            id="phone"
                                            error={errors.phone}
                                            value={fret.phone}
                                            onChange={(e) => handleChange("phone", e)}
                                            label="Téléphone de l'expéditeur"
                                            placeholder="+243..."
                                            autoComplete="off"
                                        />

                                    </div>
                                    <div className="col-md-4">
                                        <Select name="destination" id="destination" label="Destination" value={fret.destination}
                                                error={errors.destination} onChange={(e) => handleChange("destination", e)}>
                                            <option value="">Choisir</option>
                                            {trajets && trajets.map(traj =>
                                                <option key={traj.id} value={traj.id}>{traj.to}</option>)
                                            }
                                        </Select>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <Field
                                            name="recipient"
                                            id="recipient"
                                            error={errors.recipient}
                                            value={fret.recipient}
                                            onChange={(e) => handleChange("recipient", e)}
                                            label="Destinataire"
                                            placeholder="Nom..."
                                            autoComplete="off"
                                        />
                                    </div>
                                    <div className="col-md-4">

                                        <Field
                                            name="rec_phone"
                                            id="rec_phone"
                                            error={errors.rec_phone}
                                            value={fret.rec_phone}
                                            onChange={(e) => handleChange("rec_phone", e)}
                                            label="Téléphone du destinataire"
                                            placeholder="+243..."
                                            autoComplete="off"
                                        />

                                    </div>
                                </div>
                                <hr/>
                                <div className="row">
                                    <div className="col-md-12">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>Nom du colis</th>
                                                <th>Nombre</th>
                                                <th className="text-danger">Prix unitaire en CDF</th>
                                                <th>Fragile ?</th>
                                                <th>
                                                    <a className="btn btn-success" onClick={addTableRows} >
                                                        <i className="bi bi-plus-circle"></i> Ajouter un colis
                                                    </a>
                                                </th>
                                            </tr>

                                            </thead>
                                            <tbody>

                                            <TableRows catalogues={catalogues} rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleForChange} />

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-sm-4">

                                    </div>
                                </div>

                                {(rowsData[0] && rowsData[0].colisName !== "" && rowsData[0].qty !== "" && rowsData[0].brittle !== ""
                                     && rowsData[0].price !== "" && fret.customer !== "" && fret.recipient !== ""
                                    && fret.phone !== "" && fret.destination !== "" && fret.rec_phone !== "")
                                && (<MyButton
                                    text="Enregistrer"
                                    onSubmit={handleSubmit}
                                    loading={showLoader}
                                    disabled={showLoader}
                                />)}

                                {/*<MyButton*/}
                                    {/*text="Enregistrer"*/}
                                    {/*onSubmit={handleSubmit}*/}
                                    {/*loading={showLoader}*/}
                                    {/*disabled={showLoader}*/}
                                {/*/>*/}

                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewFret;
