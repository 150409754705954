import React,{useState} from 'react';
import AuthService from "../../../Services/auth/authAPI";

const ManifestDashboard = () => {


    const [me, setMe] = useState(AuthService.getAuthenticatedUser());

    return (
        <div className="container mt-2">
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body justify-content-center">
                            <h2>Tableau de bord</h2>
                            <h3>Bienvenu, <b>{me ? (<>{me.user.name}</>):(<></>)}</b></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManifestDashboard;