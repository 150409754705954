import React, {useEffect,useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import AuthService from "../../../Services/auth/authAPI";
import {toast} from "react-toastify";
import ManifestAPI from "../../../apis/manifestApi/manifestAPI";

const Manifest_header = () => {

    const navigate = useNavigate();
    const [me, setMe] = useState(AuthService.getAuthenticatedUser());
    const [loading, setLoading] = useState(true);

    const handleLogout = () => {
        AuthService.logout();
        navigate("/login")
    };

    const [setting, setSetting] = useState();
    const [pos, setPos] = useState();

    const fetchSetting = async () => {
        try{
            const data =  await ManifestAPI.findSetting();
            setSetting(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };


    const fetchPos = async () => {
        try{
            const data =  await ManifestAPI.findPos();
            setPos(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des paramettres !");
        }
    };


    useEffect(() => {
        fetchSetting();
        fetchPos()
    },[]);


    return (
        <header id="header" className="header fixed-top d-flex align-items-center">

            <div className="d-flex align-items-center justify-content-between">
                <Link to="/admin" className="logo d-flex align-items-center">
                    <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="" />
                    <span className="d-none d-lg-block">KKS EXPRESS MANIFEST</span>
                </Link>
                <i className="bi bi-list toggle-sidebar-btn"></i>
            </div>


            <div className="search-bar">
                {setting && <h5 className="text-dark">Taux du jour : <b className="text-primary">{setting.currency} CDF</b></h5>}
            </div>
            <div className="search-bar">
                {pos && <h5 className="text-dark">Point de vente : <b className="text-primary">{pos.pos_name}</b></h5>}
            </div>
            <div className="search-bar">
                {pos && <h5 className="text-dark">Ville : <b className="text-primary">{pos.pos_city}</b></h5>}
            </div>

            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">

                    <li className="nav-item d-block d-lg-none">
                        <a className="nav-link nav-icon search-bar-toggle " href="#">
                            <i className="bi bi-search"></i>
                        </a>
                    </li>

                    <li className="nav-item dropdown pe-3">

                        <a className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                            {/*<img src={process.env.PUBLIC_URL + '/assets/img/profile-img.jpg'} alt="Profile" className="rounded-circle" />*/}
                            <span className="d-none d-md-block dropdown-toggle ps-2">{me && (<>{me.user.name}</>)}</span>
                        </a>

                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                            <li className="dropdown-header">
                                <h6>{me && (<>{me.user.name}</>)}</h6>
                                <span>Manifest</span>
                            </li>
                            <li>
                                <hr className="dropdown-divider" />
                            </li>

                            <li>
                                <button className="dropdown-item d-flex align-items-center text-danger" onClick={handleLogout}>
                                    <i className="bi bi-box-arrow-right"></i>
                                    <span>Se deconnecter</span>
                                </button>
                            </li>

                        </ul>
                    </li>

                </ul>
            </nav>

        </header>
    );
};

export default Manifest_header;