import axios from "axios";
import {jwtDecode} from "jwt-decode";
import { LOGIN_API } from "../../config";
import { toast } from "react-toastify";


/**
 * Deconnection (suppression du token du local storage et du axios)
 */
function logout(){
    window.localStorage.removeItem("authToken");
    delete axios.defaults.headers.common["Authorization"];
}

/**
 * Requette http d'authentification et stockage du token du local storage et du axios
 * @param {object} credentials
 * @returns
 */
function authenticate (credentials) {
    axios.defaults.headers['Access-Control-Allow-Origin'] = "*";
    return axios
        .post(LOGIN_API, credentials)
        .then(response => response.data.token)
        .then(token => {
            //Je stock mon token dans le local storage
            window.localStorage.setItem("authToken", token);

            //On previent axios qu'on a maintenant un hearder par defaut sut toutes nos futures requettes http
            setAxiosToken(token);
        });

}

/**
 * Positionne le token sur axios
 * @param {string} token
 */
function setAxiosToken(token){
    //axios.defaults.headers["Authorization"]  = "Bearer "+token;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
    axios.defaults.headers.common['Authorization'] = "Bearer "+token;
}


/**
 * Mise en place du chargement de l'application
 */
function setup(){
    //1. voir si on a un token
    const token = window.localStorage.getItem("authToken");
    //2. Voir si le token est encore valide
    if(token){
        const {exp : expiration} = jwtDecode(token);
        //
        // if(expiration * 10000 > new Date().getTime()){
        //     setAxiosToken(token);
        // }else{
        //     logout();
        //     toast.error("Le token a expirée, veuillez vous reconnecter")
        // }

        // console.log(expiration);
        // console.log(Date.now() / 1000);

        if(expiration < Date.now() / 1000){
            logout();
            toast.error("Le token a expirée, veuillez vous reconnecter")
        }else{
            setAxiosToken(token);
        }
    }
}


/**
 * Permet de savoir si on est authentifié ou pas
 * @returns boolean
 */
function isAuthenticated(){
    //1. voir si on a un token
    const token = window.localStorage.getItem("authToken");
    //2. Voir si le token est encore valide
    if(token){
        const {exp : expiration} = jwtDecode(token);
        if(expiration * 1000 > new Date().getTime()){
            return true;
        }
        return false;
    }
    return false;
}


function getUserRoles(){
    //1. voir si on a un token
    const token = window.localStorage.getItem("authToken");
    if(token){
        const {user} = jwtDecode(token);
        return user.role;
    }
    return false;
}

function getAuthenticatedUser() {

    const token = window.localStorage.getItem("authToken");
    if(token){
        const {user, pos} = jwtDecode(token);
        return {user, pos};
    }
    return false;
}

export default {
    authenticate,
    logout,
    setup,
    isAuthenticated,
    getUserRoles,
    getAuthenticatedUser
}