import React, { useEffect, useState, useRef } from 'react';
import './printer.css';
import logo from './logo.png';
import Moment from "moment";

class ComponentToPrint extends React.Component {

    render() {

        const billet = this.props.billet;
        const hour = this.props.hour;
        const plaque = this.props.plaque;
        const param = this.props.param;
        const pos = this.props.pos;

        return (
            <div className="ms-2">
                {billet && <div className="cssTicketTicket">
                        <center><img src={logo} alt="logo" /></center>
                    <p className="centered"><b>{param && param.company_address}</b></p>
                    <hr/>
                    <p className="centered">
                        <b>TICKET N° : {billet.bus_plaque}</b> <br/>
                        <b>Bus : {plaque}</b>
                    </p>
                    <hr/>
                    <p className="ms-3"><b>Date : le {Moment(billet.created_at).format("DD-MM-YYYY, HH:mm")}</b> <br/>
                        {pos && <><b>Agence : {pos.agency_name}</b><br />
                            <b>Vendeur : {pos.vendor_name}</b>
                        </>}
                        </p>
                    <hr/>
                        <table className="ms-3 printi">
                            <thead>
                            <tr>
                                <th>Client : </th>
                                <th>{billet.customer}</th>
                            </tr>

                            <tr>
                                <th>Tél : </th>
                                <th>{billet.bus_number}</th>
                            </tr>
                            <tr>
                                <th>Origine : </th>
                                <th>{billet.from}</th>
                            </tr>
                            <tr>
                                <th>Dest : </th>
                                <th>{billet.destination}</th>
                            </tr>

                            <tr>
                                <th>Depart : </th>
                                <th>{hour}</th>
                            </tr>

                            <tr>
                                <th>Coût ttc : </th>
                                <th>{billet.amount} CDF</th>
                            </tr>
                            </thead>
                        </table>
                    {billet.fret_items && <>
                        <hr/>
                        <h4 className="ms-3 fw-bold" style={{textDecoration:"underline"}}>Colis accompagnés</h4>
                        <ul className="ms-3">
                            {billet.fret_items.map((coli) =>
                                <li style={{fontSize:"15px", fontWeight:"bold"}}>
                                    {coli.weight} {coli.name} - {coli.amount} Fc
                                </li>
                            )}
                        </ul>
                    </>}

                    <hr/>

                        <p className="centered">{param && param.company_idnat}<br />
                        {param && param.company_rccm}<br />
                            {param && param.company_impot} </p>
                    <p className="centered"><b>KKS vous souhaite un bon voyage!</b></p>
                        <p className="centered">Merci pour votre confiance!</p>
                    </div>}
            </div>
        );
    }
};
export default ComponentToPrint;
