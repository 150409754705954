function TableRowsForFret({rowsData, deleteTableRows, handleChange}) {


    return(

        rowsData.map((data, index)=>{
            const {amount, title, quantity} = data;
            return(

                <tr key={index}>

                    <td>
                        <input
                            placeholder="Designation"
                            type="text"
                            value={title}
                            onChange={(evnt) => (handleChange(index, evnt))}
                            name="title"
                            className="form-control"/>
                    </td>

                    <td>
                        <input
                            placeholder="00"
                            type="number"
                            value={quantity}
                            onChange={(evnt) => (handleChange(index, evnt))}
                            name="quantity"
                            className="form-control"/>
                    </td>

                    <td>
                        <input
                            placeholder="00 CDF"
                            type="number"
                            value={amount}
                            onChange={(evnt) => (handleChange(index, evnt))}
                            name="amount"
                            className="form-control"/>
                    </td>

                    <td><a className="btn btn-danger" onClick={()=>(deleteTableRows(index))}>
                        <i className="bi bi-trash-fill"></i>
                    </a></td>
                </tr>

            )
        })

    )

}

export default TableRowsForFret;