import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import {toast} from "react-toastify";
import FretsAPI from "../../../apis/adminApi/fretAPI";
import PvsAPI from "../../../apis/adminApi/posAPI";
import Pagination from "../../assets/Pagination";
import TableLoader from "../../loaders/TableLoader";
import PrintComponent from "./printer";
import BielletterieAPI from "../../../apis/adminApi/bielleterieAPI";
import {Link, useParams} from "react-router-dom";


const RapportDailyFretByCity = () => {

    let {city} = useParams();
    const [total, setTotal] = useState();
    const [allFrets, setAllFret] = useState([]);
    const [pvs, setpvs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);

    const [vivo, setOutPutData] = useState([]);
    const [startAt, setStartAt] = useState("");
    const [endAt, setEndAt] = useState("");


    const fetchDailyFrets = async city => {
        try{
            const data =  await FretsAPI.findAdminSalesDailyFretsByCity(city);
            setAllFret(data);
            setOutPutData(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des tickets frets !");
        }
    };


    const fetchPvs = async () => {
        try{
            const data =  await PvsAPI.findAll();
            setpvs(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement des pvs !");
        }
    };


    const fetchTotal = async city => {
        try{
            const data =  await FretsAPI.findAdminSalesDailyFretsByCity(city);
            setTotal(data);
            setLoading(false);
        }catch(error){
            toast.error("Erreur lors du chargement du total !");
        }
    };


    //Au chargement du composant on va chercher les buses
    useEffect(() => {
        fetchDailyFrets(city);
        fetchTotal(city);
        fetchPvs()
    }, [city]);


    //Gestion du changement de page
    const handleChangeCurrentPage = page => {
        setCurrentPage(page);
    };

    //Gestion de la recherche
    const handleSearch = ({currentTarget}) => {
        setSearch(currentTarget.value);
        setCurrentPage(1);
    };

    const itemsPerPage = 100;

    const filteredDailyFrets = vivo.filter(
        t =>
            t.pos_name.toLowerCase().includes(search.toLowerCase()) ||
            t.pos_city.toLowerCase().includes(search.toLowerCase()) ||
            t.customer.toLowerCase().includes(search.toLowerCase()) ||
            t.code.toLowerCase().includes(search.toLowerCase()) ||
            t.recipient.toLowerCase().includes(search.toLowerCase()) ||
            t.name.toLowerCase().includes(search.toLowerCase())
    );

    //Pagination des données
    const paginatedDailyFrets = Pagination.getData(
        filteredDailyFrets,
        currentPage,
        itemsPerPage
    );

    //console.log(allFrets);

    let counter = 1;

    const handleStartedDate = ({currentTarget}) => {
        setStartAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleEndedDate = ({currentTarget}) => {
        setEndAt(currentTarget.value);
        setCurrentPage(1);
    };

    const handleBtns = () => {

        if(startAt !== "" && endAt === "") {
            const filtered = allFrets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") === Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        } else if (startAt !== "" && endAt !== "") {
            const filtered = allFrets.filter(
                item => Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD")
                    && Moment(item.created_at).format("YYYY/MM/DD") <= Moment(endAt).format("YYYY/MM/DD"));
            setOutPutData(filtered);
        }else{
            const filtered = allFrets.filter(item=>
                Moment(item.created_at).format("YYYY/MM/DD") >= Moment(startAt).format("YYYY/MM/DD"));
            setOutPutData(filtered)
        }

    };

    function calculSubTotal(data) {
        let tCDF = 0;
        data.map((ticket) => tCDF += ticket.total_amount);
        return tCDF
    }

    const handleDeleteFret = async id => {

        try{
            await BielletterieAPI.deleteFret(id);
            toast.success("le ticket a bien été supprimée !");
            window.location.reload();
        }catch(error){
            toast.error("Erreur lors de la suppression du tickets !");
        }
    };



    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className="card-title">
                                        Total : <b className="text-primary">{paginatedDailyFrets ? paginatedDailyFrets.length : 0} </b>
                                        billets | {total && (<><b className="text-success">{calculSubTotal(paginatedDailyFrets)}</b> CDF</>)}
                                    </h2>
                                </div>
                            </div>

                            <div className="row">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <Link to="/admin/rapports/dailyfrets" className={!city ? "btn btn-primary" : "btn btn-outline-primary"}>Tous</Link>
                                    <Link to="/admin/rapports/dailyfretsByCity/Lubumbashi" className={city==='Lubumbashi' ? "btn btn-primary" : "btn btn-outline-primary"}>Lubumbashi</Link>
                                    <Link to="/admin/rapports/dailyfretsByCity/Likasi" className={city==='Likasi' ? "btn btn-success" : "btn btn-outline-success"}>Likasi</Link>
                                    <Link to="/admin/rapports/dailyfretsByCity/Fungurume" className={city==='Fungurume' ? "btn btn-danger" : "btn btn-outline-danger"}>Fungurume</Link>
                                    <Link to="/admin/rapports/dailyfretsByCity/Kolwezi" className={city==='Kolwezi' ? "btn btn-info" : "btn btn-outline-info"}>Kolwezi</Link>
                                    <Link to="/admin/rapports/dailyfretsByCity/Kamoa" className={city==='Kamoa' ? "btn btn-info" : "btn btn-outline-info"}>Kamoa</Link>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Recherche par Point de vente ou vendeur
                                        </label>
                                        <input type="text"
                                               onChange={handleSearch}
                                               value={search}
                                               name="ByAll"
                                               id="ByAll"
                                               className="form-control"
                                               placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="ByAll" className="text-dark">
                                            Date debut
                                        </label>
                                        <input type="date" onChange={handleStartedDate} value={startAt}
                                               name="startDate" id="startDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-3">
                                        <label htmlFor="endDate" className="text-dark">
                                            Date fin
                                        </label>
                                        <input type="date" onChange={handleEndedDate} value={endAt}
                                               name="endDate" id="endDate" className="form-control" placeholder="Rechercher ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <div className="form-group mt-3">
                                        <label htmlFor="filter" className="text-dark">.</label>
                                        <input type="button" onClick={handleBtns} value="Filtrer"
                                               name="filter" id="filter" className="form-control btn btn-dark" placeholder="Filtrer ..."
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group mt-4 d-flex float-end">
                                        <PrintComponent type="fret" critere={search} data={filteredDailyFrets} />
                                    </div>
                                </div>
                            </div>

                            <hr/>
                            <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Code</th>
                                    <th scope="col">De</th>
                                    <th scope="col">Vers</th>
                                    <th scope="col">Mont T</th>
                                    <th scope="col">Produits</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Vendeur</th>
                                    <th scope="col">Agence</th>
                                </tr>
                                </thead>
                                {!loading &&<tbody>
                                {paginatedDailyFrets.map((ticket) => (
                                    <tr key={ticket.id}>
                                        <td>{counter++}</td>
                                        <td>{ticket.customer}</td>
                                        <td><b className="text-success">{ticket.code}</b></td>
                                        <td>{ticket.city_from}</td>
                                        <td>{ticket.city_to}</td>
                                        <td><b>{ticket.total_amount}</b> CDF</td>
                                        <td>
                                            <ol className="list-group list-group-numbered">
                                            {ticket.fret_items &&
                                                ticket.fret_items.map((p) => (
                                                    <li key={p.id} className="list-group-item d-flex justify-content-between align-items-start">
                                                        <div className="ms-2 me-auto">
                                                            <div className="fw-bold">{p.name} - ({p.weight})</div>
                                                        </div>
                                                        <button
                                                            onClick={() => {
                                                                if (window.confirm('Etes-vous sûr(e) de vouloir effectuer cette action ?')) {
                                                                    handleDeleteFret(p.id)
                                                                }
                                                            }}
                                                            className="badge bg-danger rounded-pill">
                                                            Sup
                                                        </button>
                                                        {/*<span >14</span>*/}
                                                    </li>
                                                ))
                                            }
                                        </ol>
                                        </td>
                                        <td>{Moment(ticket.created_at).format("DD/MM/YYYY")}</td>
                                        <td>{ticket.name}</td>
                                        <td>{ticket.pos_name}</td>
                                    </tr>
                                ))
                                }

                                </tbody>}
                            </table>
                            {loading && <TableLoader width={300} height={350} />}

                            {itemsPerPage < filteredDailyFrets.length &&
                            <Pagination
                                currentPage={currentPage}
                                itemsPerPage={itemsPerPage}
                                length={filteredDailyFrets.length}
                                onPageChange={handleChangeCurrentPage}
                            />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RapportDailyFretByCity;
